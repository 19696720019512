<template>
    <hb-modal v-model="dialog" size="large" title="Edit Product">
        <template v-slot:subheader>
            <span class="hb-text-light">
                Changing the inventory threshold at a property level will override corporate settings
            </span>
        </template>
        <template v-slot:content>
            <hb-form label="Product Name">
                <p>{{ productData.name }}</p>
            </hb-form>

            <hb-form label="Label" full>
                <div class="product-images display-flex" v-if="productData.merchandise_details">
                    <div class="image-item mb-2" v-for="(image, index) in productData.merchandise_details.Images" :key="index">
                        <img :src='"data:image/" + image.image_type + ";base64," + image.data_url' alt="" srcset="">
                    </div>
                </div>
            </hb-form>

            <hb-form label="SKU">
                <p>{{ productData.sku }}</p>
            </hb-form>

            <hb-form label="Description">
                <p>{{ productData.description }}</p>
            </hb-form>

            <hb-form label="Product Weight">
                <p v-if="productData.merchandise_details">{{ productData.merchandise_details.weight_unit }}: {{ productData.merchandise_details.weight }}</p>
            </hb-form>

            <hb-form label="Quantity per Package">
                <p v-if="productData.merchandise_details">{{ productData.merchandise_details.quantity_per_package }}</p>
            </hb-form>

            <hb-form label="Negative Inventory"
                description="Selling a product when its inventory is 0 will create negative inventory and will reflect in accounting.">
                <hb-checkbox v-model="productData.negative_inventory" label="Allow selling this product past 0 inventory" />
            </hb-form>

            <hb-form label="Price" required full>
                <p class="hb-text-night-light mb-2">This product is currently priced at: ${{ productData.summary_inventory && productData.summary_inventory.average_weighted_cost ? productData.summary_inventory.average_weighted_cost.toFixed(2) : 0 }}</p>
                <hb-select
                    :clearable="false"
                    v-model="productData.amount_type"
                    :items="[{id:'fixed', name: 'Fixed'}, {id:'variable', name: 'Variable'}]" 
                    v-validate="'max:1000'"
                    placeholder="Choose Type"
                    item-text="name"
                    item-value="id"
                    data-vv-as="Notes" 
                    class="pb-0 pt-1" />

                <v-row no-gutters class="pt-1">
                    <div class="hb-inline-sentence-text mb-0 pb-0">
                        Price:
                    </div>
                    <hb-text-field
                        condensed
                        v-model="productData.price"
                        @change="fractionalValueEvent($event, 'price')"
                        box
                        medium
                        v-validate="'required|max:45|min_value:0'"
                        min="0"
                        type="number"
                        data-vv-name="product_price"
                        data-vv-as="price"
                        :error="errors.has('product_price')"
                        placeholder="$ 10.00"
                        hide-details
                        class="mb-n2 no-num-arrows"
                    />

                    <hb-checkbox class="pt-1 pl-4" v-model="defaultPriceCheck" :label="'Reset to default price of: $' + productData.merchandise_details.price" />
                </v-row>
            </hb-form>

            <hb-form label="Inventory Threshold" full>
                <div class="inline-container">
                    <span>
                        When this product’s inventory is ≤
                    </span>
                    <hb-text-field condensed box small v-model="productData.inventory_threshold"
                        v-validate="'required|max:45|min_value:0'" class="no-num-arrows" min="0" type="number" data-vv-name="inventory_threshold"
                        data-vv-as="inventory threshold" :error="errors.has('inventory_threshold')" placeholder="10" />
                    <span>
                        , its status will change to “Low Stock”
                    </span>
                </div>
            </hb-form>

            <hb-form label="Target Inventory Amount" full>
                <p class="hb-text-night-light mb-0">
                    Set the product’s desired inventory to be reached when reordering. This will reflect in inventory reports as suggested reorder amount.
                </p>
            </hb-form>

        </template>

        <template v-slot:right-actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" :loading="isLoading($options.name)" @click="save">Save</hb-btn>
        </template>
    </hb-modal>
</template>

<script type="text/babel">
import Loader from "../../assets/CircleSpinner.vue";
import api from "../../../assets/api";
import { mapGetters } from 'vuex';
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
    name: 'EditPropertyProduct',
    mixins: [ notificationMixin ],
    components: {
        Loader
    },
    props: {
        value: {
            type: Boolean
        },
        selected: {
            type: Object,
            default: {}
        },
        propertyId: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            productData: {
                merchandise_details: {},
                summary_inventory: {},
                new_price: '',
            },
            inventoryModification: null,
            modificationsList: [
                { id: 'adding_inventory', name: 'Adding Inventory' },
                { id: 'removing_inventory', name: 'Removing Inventory' },
                { id: 'transfer_inventory', name: 'Transfer Inventory' }
            ],
            removeInventoryReason: null,
            reasonsList: [
                { id: 'damaged_inventory', name: 'Damaged Inventory' },
                // { id: 'merchandise_transfer', name: 'Merchandise Transfer'},
                { id: 'write_off', name: 'Write-Off' }
            ],
            defaultPriceCheck: false
        }
    },
    computed: {
        ...mapGetters({
            primaryRoleProperties: 'propertiesStore/primaryRoleProperties'
        }),
        propertyItems() {
            return this.primaryRoleProperties.map(p => {
                return {
                    id: p.id,
                    name: p.name,
                    number: p.number,
                    city: p.city,
                    gds_id: p.gds_id,
                    disabled: false,
                }
            })
        },
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    created() {
        this.fetchProductData();
    },
    watch: {
        'selected.id': {
            handler: function(value) {
                if (value) {
                    this.fetchProductData();
                }
            },
            deep: true
        }
    },
    methods: {
        fractionalValueEvent(params,field) {
            this.productData[field] = parseFloat(params).toFixed(2);
        },
        async fetchProductData() {
            try {
                let path = api.PROPERTIES + (this.propertyId) + `/products/${this.selected.id}?type=product`;
                let response = await api.get(this, path);
                this.productData = response?.products[0];
                this.productData.negative_inventory = this.productData.negative_inventory ? true : false;
            } catch (error) {
                this.showMessageNotification({ description: error });
            }
        },
        resetData() {
            this.productData = {
                name: '',
                description: '',
                weight_unit: '',
                weight: 0
            }
        },
        async save() {
            const validationStatus = await this.$validator.validateAll();
            if (validationStatus) {
                try {
                    let payload  = {
                        "amount_type": this.productData.amount_type,
                        "price": this.defaultPriceCheck ? this.productData.merchandise_details.price : this.productData.new_price ? parseFloat(this.productData.new_price) : parseFloat(this.productData.price),
                        "negative_inventory": this.productData.negative_inventory ? true : false,
                        "inventory_threshold": parseInt(this.productData.inventory_threshold),
                        "taxable": this.productData.taxable,
                        "income_account_id": this.productData.income_account_id
                    };
                    let path = api.PROPERTIES + (this.propertyId) + `/products/${this.selected.id}`;
                    let response = await api.put(this, path, payload);
                    this.dialog = false;
                    this.$emit('fetchData');
                } catch (error) {
                    this.showMessageNotification({ description: error });
                }
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.inline-flex {
    display: inline-flex !important;
}

.left-border {
    border-left: 1px solid #ccc;
    /* Adjust color and thickness as needed */
}

.display-none {
    display: none;
}

.modal-form-bg-color {
    background: #F4F6F8;
}

.font-weight-500 {
    font-weight: 500;
}

/* Add styles for preview section */
.preview {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.preview-img {
    margin-right: 10px;
    position: relative;
    width: 100%;
}

.preview img {
    max-width: 100%;
    max-height: 100%;
}

.remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
}

/* Rest of the styles remain the same */
.image-upload {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.image-upload input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.uploading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-upload {
    font-size: 48px;
    color: #ccc;
    margin-bottom: 10px;
}

.click-to-upload {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.preview-img-border {
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    margin: 0;
}

.flex-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.no-data-box {
    padding: 30px 0;
    text-align: center;
    border: 1.5px dashed #ccc;
    border-radius: 3px;
}

.display-flex {
    display: flex;
    flex-wrap: wrap;
}

.image-item {
    max-width: 150px;
    padding: 8px;
    background-color: #DFE3E8;
    border-radius: 4px;
    margin-right: 8px;
}

.image-item img {
    width: 100%;
}

.inline-container {
    white-space: normal;
}

.inline-container > span {
    display: inline;
    position: relative;
    top: -4px;
}

.inline-container > div {
    display: inline-block;
    padding: 2px 4px;
    margin: 0 2px;
}
</style>
